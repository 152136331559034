// INPUTS -------------------- //

// base input styles
%input-base {
  display: block;
  font-size: $med-text;
  border: solid thin $colors-mercury-grey;
  border-radius: 0;
  padding: 8px;
  height: 48px;

  &:hover,
  &:focus,
  &:active {
    border: solid thin $colors-teal-green;
    outline: solid 4px $colors-wild-sand-white;
  }

  @include respond-to(tablet) {
    font-size: $body-font-size;
  }
}

// base input failed validation styles
%input-base--validation-failed {
  border: solid thin $colors-torch-red;

  &:hover,
  &:focus,
  &:active {
    border: solid thin $colors-torch-red;
    outline: solid 2px $colors-torch-red;
  }
}

%input-base--s {
  max-width: 160px;
}

%input-base--xs {
  max-width: 76px;
}

%input-base--m {
  max-width: 322px;
}

// LABELS -------------------- //

// base label styles
%labels {
  @include respond-to(tablet) {
    font-size: $body-font-size;
  }
  display: block;
  margin: 0 0 8px;
  font-size: $med-text;
}

// label component
.c-label {
  @extend %labels;

  &--inline {
    display: inline-block;
    margin: 0;
    margin-right: 10px;
  }

  &--bold {
    font-weight: bold;
  }
}

%form-item {
  display: block;
  margin: 0 0 12px;
}

// form item layout object
.o-form-item {
  @extend %form-item;
}

.c-affix {
  @include respond-to(tablet) {
    font-size: $body-font-size;
  }
  font-size: $med-text;
}

.c-error-message {
  @include respond-to(tablet) {
    font-size: $large-text;
  }
  background: $colors-torch-red-transparent-005;
  padding: 5px;
  color: $colors-torch-red;
  font-size: $med-text;
}

.c-error-summary {
  list-style-type: none;
  width: 100%;
  margin: 0;
  background: $colors-torch-red-transparent-005;
  padding: 8px;
  color: $colors-torch-red;
  font-weight: bold;

  &_link {
    color: $colors-torch-red;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
