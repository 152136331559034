%mega-menu-link-effect {
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: $colors-kaitoke-green;
    bottom: 0;
  }

  &::after {
    width: 0%;
    background: $colors-sushi-green;
    transition: width 0.26s ease-in;
  }

  &:hover,
  &:focus {
    &::after {
      width: 100%;
      bottom: 0;
    }
  }
}

.c-mega-menu {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;

  @include respond-to(tablet) {
    display: none;
    position: absolute;
    left: 0;
    margin: 0;
    top: 100%;
    color: $colors-white;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: $colors-white;
    left: calc(-1 * (100vw - 100%) / 2);
    margin: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    box-shadow: 0 10px 20px rgba($colors-black, 19%),
      0 6px 6px rgba($colors-black, 23%);

    @include respond-to(tablet) {
      background-color: $colors-deep-fir-green;
    }
  }

  &_wrapper {
    position: relative;
    padding: 10px 15px;
    margin: 0;
    width: 100%;
    z-index: 4;

    @include respond-to(tablet) {
      color: white;
      padding: 2em 0;
      max-width: 800px;
      display: flex;
      margin: 0 20%;
    }
  }

  &_heading {
    @extend %mega-menu-link-effect;
    display: flex;
    color: $colors-black;
    margin: 0;
    padding-bottom: 0.5em;
    font-size: 20px;

    @include respond-to(tablet) {
      width: 350px;
      padding: 1em 0;
    }
  }

  &_heading-link {
    color: $colors-black;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: normal;
    font-size: inherit;
    width: 100%;

    @include respond-to(tablet) {
      width: 350px;
      color: $colors-white;
    }
  }

  &_link {
    color: $colors-black;
    text-decoration: none;
    text-transform: capitalize;
    padding-left: 0.5em;

    @include respond-to(tablet) {
      padding: 0;
      color: $colors-white;
    }
  }

  &_heading-link,
  &_link {
    @include respond-to(tablet) {
      display: flex;
    }

    &::after {
      @extend %trangle;
      position: relative;
      display: inline-block;
      margin-left: 0.5em;
      padding: 0;
      background-color: transparent;
      align-self: center;
    }
  }

  &_list {
    list-style-type: none;
    padding: 0;
    margin: 0 0.3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include respond-to(tablet) {
      gap: 0 1em;
      margin: 0;
      margin-top: 0.5em;
    }
  }

  &_item {
    @extend %mega-menu-link-effect;
    width: 100%;
    padding: 0.5em 0;
    @include truncate(1);

    @include respond-to(tablet) {
      width: 350px;
      padding: 1em 0;
    }
  }
}
