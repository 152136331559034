.c-header {
  display: flex;
  width: 100%;

  &_wrapper {
    width: 100%;
    padding: 1em;
    position: relative;
  }
}
