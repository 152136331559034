.c-footer {
  &_main {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    margin: 1em 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 34px;
      left: calc(-1 * (100vw - 100%) / 2);
      right: 0;
      width: 100vw;
      height: 100%;
      background-color: $colors-deep-fir-green;
      z-index: 0;
    }
  }

  &_left {
    z-index: 2;
  }

  &_logo {
    margin-top: 0px;
    width: 200px;

    @include respond-to(tablet) {
      width: 300px;
    }

    @include respond-to(desktop) {
      width: 400px;
    }
  }

  &_logo-image {
    width: 100%;
  }

  &_bottom-logo {
    width: 60%;
    margin-top: 10px;

    @include respond-to(tablet) {
      width: 40%;
    }
  }

  &_bottom-logo-image {
    width: 100%;
  }
}
