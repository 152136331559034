%buttons {
  @include default-transition(all);
  position: relative;
  display: inline-block;
  margin: 0 4px 0 0;
  padding: 5px 33px;
  border: 1px solid $colors-black;
  background: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
}

%clear-button-styles {
  // removes browser button styling
  background: none;
  border: 0;
  padding: 0;
  height: auto;
  width: auto;
}

%buttons-hover {
  box-shadow: $default-shadow;
}

.c-btn {
  @extend %buttons;

  &:hover,
  &:focus,
  &:active {
    @extend %buttons-hover;
  }
  // sass-lint:disable class-name-format
  .svg-icon {
    // sass-lint:enable class-name-format
    width: 25px;
    height: 25px;
    margin-bottom: -3px;
    display: inline-block;
  }

  &--primary {
    background: $primary-button-background;
    color: $colors-white;
    border-color: transparent;
  }

  &--secondary {
    background: $secondary-button-background;
    color: $colors-white;
    border-color: transparent;
  }

  &[disabled="disabled"],
  &[disabled] {
    @include opacity(0.5);
    cursor: default;
  }

  &--teal {
    background: $colors-teal-green;
    color: $colors-white;
    font-weight: bold;
    font-size: 20px;
    border: 0;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background-color: $colors-teal-green-hover;
    }

    &:focus {
      border: solid thin $colors-teal-green;
      outline: solid 4px $colors-wild-sand-white;
    }
  }

  &--teal-outline {
    background: $colors-white;
    color: $colors-teal-green;
    border: 2px solid $colors-teal-green;
    font-weight: bold;
    font-size: 20px;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background-color: $colors-teal-green-transparent-01;
    }
  }
}
