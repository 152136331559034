$breakpoints: (
  mobile: 320px,
  mobile-large: 400px,
  tablet: 768px,
  desktop: 1024px,
);

// Grid settings
$columns: 12;
$column-width: 60px;
$gutter-width: 20px;
$site-max-width: 1252px;
$site-content-max-width: 1600px;
$site-secondary-max-width: 1900px;

// Font sizes
$med-text: 18px;
$large-text: 24px;
$body-font-size: 18px;

// buttons
$primary-button-background: $colors-kaitoke-green;
$secondary-button-background: $colors-sushi-green;

$colors-teal-green-hover: darken($colors-teal-green, 5);
$colors-teal-green-transparent-01: rgba($colors-teal-green, 0.1);

// forms
$validation-text-color: $colors-burnt-sienna;
$mandatory-icon-color: $colors-burnt-sienna;
$form-highlight-color: $colors-celery;

// box shadows
$default-shadow: 0 1px 3px rgba($colors-black, 0.12),
  0 1px 2px rgba($colors-black, 0.24);
$hover-shadow: 0 10px 20px rgba($colors-black, 0.19),
  0 6px 6px rgba($colors-black, 0.23);
$large-shadow: 0 14px 28px rgba($colors-black, 0.25),
  0 10px 10px rgba($colors-black, 0.22);
