.c-select {
  @extend %input-base;
  width: 100%;
  appearance: none;

  &--inline {
    display: inline-block;
  }

  // sass-lint:disable
  &::-ms-expand {
    display: none;
  }
  // sass-lint:enable

  &_arrow {
    pointer-events: none;
    position: absolute;
    right: 15px;
    top: 50%;
    border: solid $colors-teal-green;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(45deg) translateY(-50%) translateX(-50%);
  }

  &_wrapper {
    position: relative;
    width: 100%;
  }
}

.o-form-item1--validation-failed {
  .c-select {
    @extend %input-base--validation-failed;
  }
}
