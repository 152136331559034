.c-skip-site {
  display: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &_item {
    margin: 0;
    padding: 0;
    line-height: 1.5em;
  }

  &_link {
    color: $colors-white;
    position: absolute;
    top: -999999px;
    left: 0;
    z-index: 500;
    padding: 6px;
    text-align: center;

    &:active,
    &:focus {
      position: absolute;
      background-color: $colors-kaitoke-green;
      left: 0;
      top: 0;
      outline: 0;
      z-index: 500;
      width: 100%;
    }
  }

  @include respond-to(desktop) {
    display: block;
  }
}
