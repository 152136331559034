@mixin truncate($lines) {
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  /*! autoprefixer: off */
}

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @error "Unfortunately, no value could be retrieved from `#{$breakpoint}`." + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin clearfix {
  //*zoom: 1;

  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}

// default transition - mostly used for hover effects
@mixin default-transition($transition-target) {
  transition: $transition-target 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@mixin opacity($trans) {
  //  filter: unquote("alpha(opacity=" + ($trans * 100) + ")"); // IE6 to IE8
  opacity: $trans;
  zoom: 1;
}
