// these global css varaibles have been added to allow tailwind transform related utilities to work e.g rotate-180
// this is because only tailwind base includes these variables and we dont want to include the other styles imported by
// tailwind base. Hopefully they add these variables as a separate import further down the line and this can be removed.
:root {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0deg;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;

  --tw-blur: blur(0);
  --tw-brightness: brightness(0);
  --tw-contrast: contrast(0);
  --tw-grayscale: grayscale(0);
  --tw-hue-rotate: hue-rotate(0deg);
  --tw-invert: invert(0);
  --tw-opacity: opacity(0.5);
  --tw-saturate: saturate(0);
  --tw-sepia: sepia(0);
  --tw-blur: blur(0);

  --tw-backdrop-brightness: brightness(0);
  --tw-backdrop-contrast: contrast(0.5);
  --tw-backdrop-grayscale: grayscale(0);
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
  --tw-backdrop-invert: invert(0);
  --tw-backdrop-opacity: opacity(0.5);
  --tw-backdrop-saturate: saturate(0);
  --tw-backdrop-sepia: sepia(0);
}

%to-viewport-edge {
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 500%;
    background-color: inherit;
  }
}

// extend backgorund color of element beyond edge of screen e.g for blocks that sit
// inside main content area but have a background that stretches to viewport edge.
.u-to-viewport-edge-x {
  @extend %to-viewport-edge;

  &:before {
    left: -500%;
  }

  &:after {
    right: -500%;
  }
}
