.c-input-text {
  @extend %input-base;
  width: 100%;

  &--inline {
    display: inline-block;
    margin-right: 8px;
  }

  &--xs {
    @extend %input-base--xs;
  }

  &--validation-failed {
    @extend %input-base--validation-failed;
  }
}

.o-form-item--validation-failed {
  .c-input-text {
    @extend %input-base--validation-failed;
  }
}
