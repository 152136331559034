* {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

%list-clear {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%trangle {
  content: "";
  display: inline-block;
  border: 6px solid transparent;
  border-left-color: currentColor;
}

%grid-container {
  max-width: $site-max-width;
  margin: 0 auto;
  padding: 0 16px;
}
