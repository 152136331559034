@use "sass:math";

/////////////////
// Semantic.gs // for SCSS: https://github.com/tylertate/semantic.gs http://tylertate.github.io/semantic.gs/
/////////////////

// Utility function — you should never need to modify this
@function gridsystem-width($columns: $columns) {
  @return ($column-width * $columns) + ($gutter-width * $columns);
}

// Set $total-width to 100% for a fluid layout
$total-width: 100%;

// GRID //
@mixin row($columns: $columns) {
  @include clearfix;
  display: block;
  width: $total-width *
    math.div(
      $gutter-width + gridsystem-width($columns),
      gridsystem-width($columns)
    );
  margin: 0 $total-width *
    math.div($gutter-width * 0.5, gridsystem-width($columns)) * -1;
}

@mixin column($x, $columns: $columns) {
  display: inline;
  float: left;
  width: $total-width *
    math.div(
      (($gutter-width + $column-width) * $x) - $gutter-width,
      gridsystem-width($columns)
    );
  margin: 0 $total-width *
    math.div($gutter-width * 0.5, gridsystem-width($columns));
}

// fixed division depreciation warnings from dart sass, below mixins still need fixing.

// @mixin mincolumn($x, $columns: $columns) {
//     display: block;
//     float: left;
//     width: auto;
//     min-width: $total-width *
//         (
//             ((($gutter-width + $column-width) * $x)-$gutter-width) /
//                 gridsystem-width($columns)
//         );
//     margin: 0 $total-width *
//         (($gutter-width * 0.5) / gridsystem-width($columns));
//     // *width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns))-$correction;
//     // *margin: 0 $total-width*(($gutter-width*.5)/gridsystem-width($columns))-$correction;
// }

// @mixin columninner($x, $columns: $columns) {
//     display: inline;
//     float: left;
//     width: 100%;
//     padding: 0 $total-width * (($gutter-width) / gridsystem-width($columns));
//     // *width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns))-$correction;
//     // *margin: 0 $total-width*(($gutter-width*.5)/gridsystem-width($columns))-$correction;
// }

// @mixin push($offset: 1) {
//     margin-left: $total-width *
//         (
//             (($gutter-width + $column-width) * $offset) /
//                 gridsystem-width($columns)
//         ) + $total-width * (($gutter-width * 0.5) / gridsystem-width($columns));
// }

// @mixin pull($offset: 1) {
//     margin-right: $total-width *
//         (
//             (($gutter-width + $column-width) * $offset) /
//                 gridsystem-width($columns)
//         ) + $total-width * (($gutter-width * 0.5) / gridsystem-width($columns));
// }
