/* Converted Tailwind Config to scss */
$colors-kaitoke-green: (rgba(0, 84, 50));
$colors-burnham-green: (rgba(0, 57, 33));
$colors-deep-fir-green: (rgba(0, 39, 14));
$colors-sushi-green: (rgba(122, 193, 67));
$colors-cerulean-blue: (rgba(0, 181, 204));
$colors-orange-peel: (rgba(245, 156, 0));
$colors-teal-green: (rgba(0, 133, 127));
$colors-teal-pine-green: (rgba(0, 112, 107));
$colors-fiord-blue: (rgba(66, 89, 104));
$colors-olive-green: (rgba(72, 137, 36));
$colors-torch-red: (rgba(243, 27, 27));
$colors-wild-sand-white: (rgba(246, 246, 246));
$colors-prussian-blue: (rgba(0, 59, 78));
$colors-silver-grey: (rgba(198, 198, 198));
$colors-mercury-grey: (rgba(230, 230, 230));
$colors-black: (rgba(0, 0, 0));
$colors-mine-shaft-grey: (rgba(38, 38, 38));
$colors-white: (rgba(255, 255, 255));
$colors-white-005: (rgba(255, 255, 255, 0.05));
$colors-burnt-sienna: (rgba(241, 102, 81));
$colors-dove-grey: (rgba(112, 112, 112));
$colors-alto-grey: (rgba(215, 215, 215));
$colors-edward-grey: (rgba(161, 164, 164));
$colors-tundora-grey: (rgba(53, 53, 53));
$colors-celery: (rgba(215, 73, 12));
$colors-pale-sky-blue: (rgba(103, 122, 134));
$colors-nevada-blue: (rgba(94, 114, 127));
$colors-ethereal-white: (rgba(229,243,242));
$colors-seal-pup: (rgba(98, 136, 154));
$colors-alabaster-grey: (rgba(249, 249, 249));
$colors-gray-nurse: (rgba(241, 243, 241));
$colors-aqua-squeeze: (rgba(229, 243, 242));
$colors-concrete-grey: (rgba(242, 242, 242));
$colors-teal-green-transparent-01: (rgba(0, 133, 127, 0.1));
$colors-teal-green-transparent-03: (rgba(0, 133, 127, 0.3));
$colors-teal-green-transparent-003: (rgba(0, 133, 127, 0.03));
$colors-fiord-blue-transparent-05: (rgba(66, 89, 104, 0.5));
$colors-mine-shaft-grey-transparent-05: (rgba(38, 38, 38, 0.5));
$colors-black-transparent-06: (rgba(0, 0, 0, 0.6));
$colors-sushi-green-transparent: (rgba(122, 193, 67, 0.15));
$colors-torch-red-transparent-005: (rgba(215, 73, 12, 0.05));
$colors-transparent: transparent;
