.c-pagination {
  &_controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include respond-to(tablet) {
      justify-content: center;
      flex-wrap: nowrap;
    }
  }

  &_page-number {
    padding: 4px;
    border-bottom: solid 3px transparent;

    &--active {
      border-bottom: solid 3px $colors-sushi-green;
    }
  }

  &_button {
    @extend %clear-button-styles;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;

    // sass-lint: disable-all
    .c-svg-icon {
      width: 12.5px;
      height: 12.5px;
    }

    &--border-bottom {
      border-bottom: solid 1px $colors-sushi-green;
    }

    // sass-lint: enable-all
  }
}
