.l-grid-container {
  @include respond-to(mobile) {
    max-width: $site-max-width;
    margin: 0 auto;
    padding: 0 16px;
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.l-col-12 {
  @include column(12);
}

.l-row {
  @include row;
}
