.c-logo {
  &_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.c-nav-toggle {
  &_btn {
    //mobile burger button
    position: relative;
    width: 40px;
    height: 30px;
    margin-left: 0;
    padding: 0;
    border: 0;
    background: transparent;
    font-size: 0;
    vertical-align: top;

    &::after,
    &::before,
    span {
      @include default-transition(all);
      content: " ";
      position: absolute;
      display: block;
      width: 40px;
      height: 5px;
      top: 0;
      left: 0;
      margin: 0 auto;
      background: $colors-kaitoke-green;
      transform-origin: 0 0;
    }

    &::before {
      top: 12px;
    }

    &::after {
      top: 24px;
    }

    &.open {
      span {
        width: 0;
        left: 20px;
        background: transparent;
      }

      &::before {
        transform: translate3d(5px, 0, 0) rotate(45deg);
        top: 0;
      }

      &::after {
        transform: translate3d(2px, 3px, 0) rotate(-45deg);
      }
    }
  }
}

.c-main-nav {
  $self: &;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  @include respond-to(tablet) {
    top: 76px;
    background-color: transparent;
  }

  &_container {
    display: flex;
    flex-wrap: wrap;
    z-index: 4;
    width: 100%;

    @include respond-to(tablet) {
      justify-content: end;
      align-items: end;
      position: relative;
    }
  }

  &_nav {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    padding: 0;

    @include respond-to(tablet) {
      margin: 0 1em;
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-items: end;
    width: 100%;
    margin: 0;
    padding: 0;

    @include respond-to(tablet) {
      flex-direction: row;
      gap: 2em;
      width: auto;
    }
  }

  &_item {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include respond-to(tablet) {
      width: auto;
    }

    &:hover {
      .c-mega-menu {
        display: block;
        z-index: 3;
      }
    }

    // if the child of this item is hovered then reveal it
    #{$self}_item .c-mega-menu {
      &:hover {
        display: block;
        z-index: 3;
      }
    }
  }

  &_link {
    @include truncate(1);
    display: flex;
    position: relative;
    text-transform: uppercase;
    font-size: 20px;
    color: $colors-white;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    background-color: $colors-kaitoke-green;
    padding: 0 0.3em;

    @include respond-to(tablet) {
      justify-content: flex-start;
      align-items: flex-end;
      width: auto;
      padding: 5px 0;
      color: $colors-black;
      background-color: transparent;
    }

    &::after {
      display: none;
    }

    &:focus,
    &:hover {
      &::after {
        content: "";

        @include respond-to(tablet) {
          display: block;
          position: absolute;
          width: 100%;
          height: 10px;
          top: 105%;
          margin: -10px 25px 0 0;
          background-color: $colors-sushi-green;
        }
      }
    }

    &.active {
      background: darken($colors-white, 5);
    }
  }

  &_link-title {
    text-align: left;
    width: auto;
    padding: 10px 15px;

    @include respond-to(tablet) {
      padding: 0;
      @include truncate(1);
    }
  }
}
