// Some css for c-filters is also found in the prime tool css.
// To avoid clashes avoid adding styles to c-filters directly
// use modifiers and/or tailwind utilities instead.
.c-filters {
  width: 100%;

  &_summary {
    font-weight: bold;
    position: relative;
    padding: 1rem 0;
    font-size: 18px;
    list-style: none;
    &::-webkit-details-marker {
      display: none;
    }
  }

  &_summary-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &_form-item:first-of-type + button {
    @include respond-to(tablet) {
      margin-left: -1em;
    }
  }
}
