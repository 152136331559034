.c-loading {
  @include respond-to(tablet) {
    width: 500px;
    height: 300px;
  }

  background: $colors-white;
  height: 300px;
  margin: auto;
  text-align: center;
  width: 90%;
  z-index: 1;
}

.c-spinner {
  margin: 50px auto;
  position: relative;
  width: 80px;
  height: 80px;

  &_segment {
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: $colors-teal-green;
    animation: rotate linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    animation-duration: 0.8s;
  }

  &_circle {
    width: 80px;
    height: 80px;
    border: solid 5px $colors-teal-green;
    border-radius: 50%;
    opacity: 0.3;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
